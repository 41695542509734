.elem_container {
  display: flex;
  margin: 0 10px;
}

.none_select {
  user-select: none;
}

.elem_text {
  margin: 10px 6px;
  font-size: 18px;
}
