.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  font-size: 1.8rem;
  color: white;
  transition: all 375ms ease;
  overflow: hidden;
}

.tile:hover {
  transition: all 225ms ease;
  overflow: hidden;
}

@media screen and (orientation: portrait) {
  .tile {
  }
}
