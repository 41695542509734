body {
  margin: 0;
  color: #c0bfbf;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
